header {
  width: 100%;
  height: 10vh;
  font-size: 9vh;
  /* letter-spacing: 2px; */
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#logo {
  /* width: 3.2%;
  height: 64%; */
  width: 2.6%;
  height: 53%;
  /* margin: 0 30px; */
}

#segments-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  float: right;
  /* display: flex;
  justify-content: space-evenly; */
}


.segment {
  width: 20%;
  display: inline-block;
  position: relative;
  height: 100vh;
  cursor: pointer;
}

.expanded {
  /* width: calc(100%); */
  width: 100%;
  /* left: 0; */
  /* margin-left: -10px; */
  transition: all 1s ease-out;
}

.testnone {
  /* color: white;
  background-color: white;
  border: none; */
  display: none;
}


.disableClick {
  pointer-events: none;
}


#segment-1 {
  /* width: 20%; */
  background-color: #0075b4;
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),url("./images/linkedIn_logo.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 9%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin: 0;
}


#segment-2 {
  /* width: 20%; */
  background-color: #9232ae;
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),url("./images/github_logo.webp"); */
  background-repeat: no-repeat;
  background-size: 331%;
  background-position: 28%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin: 0;
}

#segment-3 {
  /* width: 20%; */
  background-color: white;
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3)),url("./images/shades.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 23%;
  /* background-position: 59%; */
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin: 0;
}

#segment-4 {
  /* width: 20%; */
  background-color: #fea116;
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),url("./images/leetcode_logo.png"); */
  background-repeat: no-repeat;
  background-size: 300%;
  background-position: 32%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin: 0;
}

#segment-5 {
  /* width: 20%; */
  background-color: rgb(16, 167, 16);
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),url("./images/pdf_logo.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 15%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin: 0;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeout-background {
  animation: fadeout 1s ease forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadein-background {
  animation: fadein 1s ease forwards;
}




#segment-1:hover {
  filter: grayscale(0);
  /* background-image: url("./images/linkedIn_logo.png"); */
}
#segment-2:hover {
  filter: grayscale(0);
  /* background-image: url("./images/github_logo.webp"); */
}
#segment-3:hover {
  filter: grayscale(0);
  background-image: url("./images/shades.JPG");
}
#segment-4:hover {
  filter: grayscale(0);
  /* background-image: url("./images/leetcode_logo.png"); */
}
#segment-5:hover {
  filter: grayscale(0);
  /* background-image: url("./images/pdf_logo.png"); */
}




.close {
  position: absolute;
  right: 32px;
  top: 17%;
  width: 93px;
  height: 32px;
  opacity: 0.5;
  cursor: pointer;
}
.close:hover {
  opacity: 2;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

#buttonPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 81vh;
}

.iconButton {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

#iconButton-1 {
  background-image: url("./images/linkedin_button.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 10px 20px 10px;
  border: 1px solid #BFBFBF;
  background-color: white;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3); 
  margin: 50px auto;
  transition: all 0.2s ease;
  cursor: pointer;
}

#iconButton-1:active {
  transform: translateY(4px);
  box-shadow: 0 1px gray;
}

#iconButton-2 {
  background-image: url("./images/github_button.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3); 
  margin: 50px auto;
  transition: all 0.2s ease;
  cursor: pointer;
}
#iconButton-2:active {
  transform: translateY(4px);
  box-shadow: 0 1px gray;
}

#iconButton-3 {
  position: relative;
  background-image: url("./images/shades.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 500px;
  margin-top: 24%;
  margin-bottom: 11%;
}

#iconButton-4 {
  background-image: url("./images/leetcode_logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  border: 6px solid #fea116;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3); 
  margin: 50px auto;
  transition: all 0.2s ease;
  cursor: pointer;
}
#iconButton-4:active {
  transform: translateY(4px);
  box-shadow: 0 1px gray;
}

#iconButton-5 {
  background-image: url("./images/adobe_logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  border: 6px solid gray;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3); 
  margin: 50px auto;
  transition: all 0.2s ease;
  cursor: pointer;
}
#iconButton-5:active {
  transform: translateY(4px);
  box-shadow: 0 1px gray;
}

#aboutMe {
  width: 40%;
}

#contactMe {
  font-size: 200%;
  text-decoration: none !important;
  color: green;
}

@media screen and (min-width: 400px) {
  #root {
    background-color: lightgreen;
  }
  header {
    width: 100%;
    height: 10vh;
    font-size: 9vh;
    /* letter-spacing: 2px; */
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}